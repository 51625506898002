import { Radio } from "@chakra-ui/react"
export const Option = ({option, setSelectedOption}) => {
    const isEdit = false
   function handleClick(e){
        if(!isEdit){
            return null
        } else {
            setSelectedOption(e.target.value)
        }
    }
    return (
        <Radio isDisabled value={option?.value} onClick={(e) => handleClick(e)}>{option?.value}</Radio>
    )
}