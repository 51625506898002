import axios from "axios"
const API_BASE_URL = "https://api.pressures.app";

// header creation
// const createHeaders = () => {
//   const token = getToken();
//   return {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };
// };

export const fetchPressureWithId = async (id) => {
    try {
        const response  = await axios.get(`${API_BASE_URL}/pressure/${id}`)
        return response
    } catch (error) {
        alert('Error fetching pressure with id', error)
    }
}