import {Stack, Flex, Text, RadioGroup, Button, Divider, Card, Grid, GridItem} from '@chakra-ui/react'
import { Option } from './Option'
import React, {useState} from 'react'
import { Loading } from './Loading'
import { isOnIOS } from '../../Utilities'
import { useParams } from 'react-router-dom'

function Form({data}){
    const params = useParams();
    const [selectedOption, setSelectedOption] = useState('')
    const [comment, setComment] = useState('')
    console.log(data)


    function toVotingZoneString(votingScope){
        switch(votingScope){
            case "worldwide":
                return "Global";
            case "state":
                return data.state;
            case "city":
                return data.city;
            case "zip":
                return data.zip;
            case "country":
                return data.country;
            default: 
                return votingScope;
        }
    }

    if (data) {
        return (
            <Stack gap={8} w={'80%'}>
                {isOnIOS() && params.pressureId?
                    <Button 
                    width="auto"
                    p={5}
                    colorScheme="blue"
                    margin="4px"
                    fontSize={['sm', 'md', 'lg']}
                    onClick={()=>{
                        var now = new Date().valueOf();
                        setTimeout(function () {
                            if (new Date().valueOf() - now > 100) return;
                            window.location = `../appRedirect/${params.pressureId}`;
                        }, 25);
                        window.location = `api.pressures.app://p/${params.pressureId}`;
                    }}
                    >
                    Vote and comment anonymously
                    </Button> 
                : null}
                <Flex justifyContent={'center'}>
                    <Text fontSize={['x-large', 'xx-large', 'xxx-large']}>
                        {data?.title}
                    </Text>
                </Flex>
                <Flex justifyContent={'center'}>
                    <Text fontSize={'large'}>
                        Date: {new Date(data?.created_formatted).toLocaleDateString()}
                    </Text>
                </Flex>
                <Flex>
                    <Text style={{whiteSpace: "pre-line"}}>
                        {data?.description}
                    </Text>
                </Flex>
                
                {data?.targetName ?
                    <>
                    <Divider/>
                    <Flex>
                        <Text style={{fontWeight: 'bold'}}>
                            Target: {data?.targetName}
                        </Text>
                    </Flex>
                    </>
                : null}
                <Divider/>
                <Grid templateColumns='auto auto' gap={6} fontSize={['sm', 'md', 'lg']}>
                    <GridItem>
                        <Text style={{whiteSpace: "nowrap"}}><b>Category:</b> {data?.category}</Text>
                    </GridItem>
                    <GridItem>
                        <Text style={{whiteSpace: "nowrap"}}><b>Voting Zone:</b> {toVotingZoneString(data?.votingScope)}</Text> 
                    </GridItem>
                    <GridItem>
                        <Text style={{whiteSpace: "nowrap"}}><b>Origin:</b> {data?.city} {data?.state}, {data?.country}</Text> 
                    </GridItem>
                    <GridItem>
                        {data?.optionShowVoteCount ? <Text style={{whiteSpace: "nowrap"}}><b>Votes:</b> {data?.votes.length}</Text> : null}
                    </GridItem>
                </Grid>
                <Divider/>
                <Flex>
                    <RadioGroup mb={6}>
                        <Stack direction='column' spacing={5}>
                            {data?.responseOptions.map((item) => {
                                return <Option key={item.uniqueId} option={item} setSelectedOption={setSelectedOption}/>
                            })}
                        </Stack>
                    </RadioGroup>
                    
                </Flex>
                {isOnIOS() && params.pressureId?
                    <Button 
                        width="auto"
                        p={5}
                        colorScheme="blue"
                        margin="4px"
                        fontSize={['sm', 'md', 'lg']}
                        onClick={()=>{
                            var now = new Date().valueOf();
                            setTimeout(function () {
                                if (new Date().valueOf() - now > 100) return;
                                window.location = `../appRedirect/${params.pressureId}`;
                            }, 25);
                            window.location = `api.pressures.app://p/${params.pressureId}`;
                        }}
                        >
                        Vote and comment anonymously
                        </Button> 
                : null}
                {data.optionShowComments === "beforeVoting" ? 
                    <>
                    <Divider/>
                    <Stack>
                        <Text fontSize={['xl', '2xl', '3xl']}>Comments</Text>
                        
                        {data.votes.map(voteObj=>
                            <Card variant='outline' padding={'5px 10px 5px 10px'}>
                                <Flex direction='row' justify='space-between'>
                                    <Stack>
                                        <Text fontSize={['sm', 'md', 'lg']}>{voteObj.response.value}</Text>
                                        <Text fontSize={['sm', 'md', 'lg']}>"{voteObj.comment}"</Text>
                                    </Stack>
                                    <Stack>
                                        <Text align='right' fontSize={['xs', 'sm']}>Origin: {voteObj.city}, {voteObj.state}, {voteObj.country}</Text>
                                        <Text align='right' fontSize={['xs','sm']}>{new Date(voteObj.created_time).toLocaleDateString()}</Text>
                                    </Stack>
                                </Flex>
                            </Card>
                        )}
                    </Stack>
                    </>
                :null}
                {data?.agendas.length > 0 ?
                <>
                    <Divider/>
                    <Stack>
                        {data.agendas.map(addenda=>
                            <Card variant='outline' padding={'5px 10px 5px 10px'}>
                                <Flex direction='row' justify='space-between'>
                                    <Text>{addenda.description}</Text>
                                    <Text>{new Date(addenda.created_formatted).toLocaleDateString()}</Text>
                                </Flex>
                            </Card>
                        )}
                    </Stack>
                </>
                : null}
                {isOnIOS() && params.pressureId && data.optionShowComments === "beforeVoting" && data.votes.length ?
                    <Button 
                    width="auto"
                    p={5}
                    colorScheme="blue"
                    margin="4px"
                    fontSize={['sm', 'md', 'lg']}
                    onClick={()=>{
                        var now = new Date().valueOf();
                        setTimeout(function () {
                            if (new Date().valueOf() - now > 100) return;
                            window.location = `../appRedirect/${params.pressureId}`;
                        }, 25);
                        window.location = `api.pressures.app://p/${params.pressureId}`;
                    }}
                    >
                    Vote and comment anonymously
                    </Button> 
                : null}
            </Stack>
        )
    } else return <Loading/>
    
}

export default Form